<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm8 md4>
        <v-card flat height="100%">
          <v-card-title text-center>
            {{ this.$store.getters.company.name }}
          </v-card-title>
          <v-card-text class="pt-6">
            <v-form v-model="valid" v-if="act" ref="itemForm" @submit="item_submit">
              <v-row>
                <v-col cols="12" md="12">
                  Para registrarte como Cliente Mayorista, necesitamos verifiar su número
                  de celular. Enviaremos un SMS a su teléfono con el código de activación
                  de su dispositivo.
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="item.mobile"
                    label="Teléfono Celular"
                    hide-details="auto"
                    type="number"
                    :rules="f_required"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn class="success" block large type="submit"> Enviar </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-form ref="formtoken" v-if="token_dialog" @submit="check_token">
              <v-card elevation="0" class="mx-auto" max-width="344">
                <v-card-title class="text-center">
                  Por favor escriba el código númerico.
                </v-card-title>
                <v-card-text>
                  <v-text-field
                    :loading="true"
                    v-model="act_code"
                    :rules="codeRules"
                    type="number"
                    label="Código de activación"
                    required
                    outlined
                  >
                  </v-text-field>
                </v-card-text>
                <v-card-actions class="text-center">
                  <v-btn color="success" type="submit" block class="mr-4">
                    Activar mi dispositivo ({{ countDown }})
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-card-text>
        </v-card>

        <v-snackbar
          v-model="snackToast.dialog"
          :timeout="snackToast.timeout_error"
          absolute
          bottom
          dark
          :color="snackToast.color"
          elevation="24"
        >
          {{ this.snackToast.text }}
        </v-snackbar>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
// import createItem from "../../utils/createStore.js";
import createWebset from "../../utils/createSetting.js";
import cities_lst from "../../utils/colCities.js";
import createParty from "../../utils/createParty.js";
export default {
  name: "Registration",
  components: {},
  data() {
    return {
      edit: false,
      menuPhoto: null,
      QRdialog: false,
      stopCamera: false,
      initCamera: false,
      result: "",
      productPhoto: null,
      viewMap: false,
      recenter: false,
      ipgeo: null,
      cities: cities_lst().Colombia,
      countries: [],
      state: [],
      city: [],
      loading_status: false,
      tab: null,
      dialog_confirm: false,
      compressed_image: null,
      compressed_menu: null,
      reg_delete: null,
      valid: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      f_email_val: [
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Correo no valido",
      ],
      codeRules: [
        (v) => !!v || "El código es requerido",
        (v) => v.length === 6 || "EL código debe tener 6 números].",
      ],
      passwordRules: [
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&])(?=.{8,})/.test(v) ||
          "Mínimo 8 caracteres, con al menos una letra mayuscula, un numero y un caracter especial.",
      ],
      type_id: [
        { value: "13", text: "Cédula de ciudadanía" },
        { value: "31", text: "NIT" },
        { value: "11", text: "Registro civil" },
        { value: "12", text: "Tarjeta de identidad" },
        { value: "21", text: "Tarjeta de extranjería" },
        { value: "22", text: "Cédula de extranjería" },
        { value: "41", text: "Pasaporte" },
        { value: "42", text: "Documento de identificación extranjero" },
        { value: "50", text: "NIT de otro país" },
      ],
      typeaccountsID: [
        { value: "1", text: "Persona Juridica" },
        { value: "2", text: "Persona Natural" },
      ],
      TaxLevelCodes: [
        { value: "04", text: "Simple" },
        { value: "05", text: "Ordinario" },
      ],
      stloc: null,
      fontsLst: ["Amatic", "Architects", "Carattere", "Montserrat", "Roboto", "Shadows"],
      groups: [
        "Bodega",
        "Isla Centro Comercial",
        "Plaza de Comidas",
        "Local",
        "Local Centro Comercial",
        "Oficina",
      ],
      subgroups: [
        "Restaurante",
        "Comida Rápida",
        "Calzado",
        "Ferretería",
        "Farmacia",
        "Miscelanea",
        "Ropa",
        "Celualres",
        "Frutas y Verduras",
        "Congelados",
        "Carnes",
        "Mariscos",
        "Aves",
        "Aseo",
        "Bebidas",
        "Licores",
        "Papelería",
        "Desechables",
        "Maquinaria y Equipos",
        "Lacteos",
        "Heladería",
      ],
      webSet: createWebset(),
      item: createParty(),
      act_code: "",
      countDown: 30,
      token: "",
      snackToast: {
        timeout_error: 1000,
        dialog: false,
        text: "",
        color: "success",
      },
      value: false,
      categories: [
        { value: "store", text: "Cliente mayorista" },
        { value: "supplier", text: "Proveedor mayorista" },
      ],
      best_height: "100%",
      best_widht: "500",
      act: true,
      token_dialog: false,
    };
  },
  mounted() {
    // if (!this.$store.getters.company.account) {
    //   this.$router.push("/");
    // }
    window.addEventListener("resize", this.onResize, { passive: true });
    this.onResize();
  },
  methods: {
    item_submit(e) {
      e.preventDefault();
      this.valid = this.$refs.itemForm.validate();
      if (this.valid) {
        var qry = {
          account: this.$store.getters.company.account,
          mobile: this.item.mobile,
        };
        console.log(qry);
        webserver("get_client_token", qry, (data) => {
          if (data.token) {
            this.token = data.token;
            this.act = false;
            this.token_dialog = true;
            this.countDown = 30;
            this.countDownTimer();
            if (data.profile.account && data.profile.mobile) {
              this.item = data.profile;
            }
          } else {
            this.snackToast = {
              dialog: true,
              text: "No podemos procesar su registro. El número de documento ya existe.",
              color: "red",
            };
          }
        });
      }
    },
    check_token(e) {
      e.preventDefault();
      this.loading_status = true;
      var md5 = require("md5");
      if (md5(this.act_code) === this.token) {
        const encryptedText = this.CryptoJS.AES.encrypt(
          JSON.stringify(this.item),
          process.env.VUE_APP_QR4
        ).toString();
        window.localStorage.setItem("profile", encryptedText);
        this.act = false;
        this.token_dialog = false;
        this.$store.dispatch("setProfile", this.item);
        this.countDown = -1;
        this.$router.push("/Profile");
      } else {
        this.snackToast = {
          dialog: true,
          text: "El código ingresado no es correcto.",
          color: "warning",
        };
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_widht = window.innerWidth;
      }
    },
    returnCap(event) {
      this.item[event.target.id] = event.target.value.toUpperCase();
    },
    onlyNumber($event) {
      var keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    resetFrom() {
      this.item = createParty();
      this.act = true;
      this.token_dialog = false;
      this.profile_dialog = false;
      this.reg_dialog = true;
      localStorage.removeItem("ngm_wh");
    },

    delete_itm(data) {
      this.reg_delete = data.code;
      this.dialog_confirm = true;
    },
    delete_item() {
      var record = this.reg_delete;
      this.loading_status = true;
      var qry = {
        table: this.table,
        filters: {
          account: this.$store.state.profile.account,
          code: record,
        },
      };
      webserver("delete_record", qry, () => {
        this.reg_delete = null;
        this.dialog_confirm = false;
        this.loading_status = false;
        this.$emit("refresh", true);
      });
    },
    close() {
      this.$emit("close", true);
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },

    // profile_submit(e) {
    //   e.preventDefault();
    //   this.loading_status = true;
    //   this.item.agent = "NGM";
    //   this.item.status = "Inactive";
    //   this.item.account = this.$store.getters.company.account;
    //   var qry = {
    //     account: this.$store.getters.company.account,
    //     table: "clients",
    //     data: [this.item],
    //   };
    //   webserver("put_table", qry, (data) => {
    //     this.loading_status = false;
    //     const encryptedText = this.CryptoJS.AES.encrypt(
    //       JSON.stringify(data),
    //       process.env.VUE_APP_QR4
    //     ).toString();
    //     window.localStorage.setItem("cli", encryptedText);

    //     this.$store.dispatch("setProfile", data);
    //     this.$router.push("/");
    //     this.snackToast = {
    //       dialog: true,
    //       text: "Sus datos han sido registrados con EXITO !",
    //       color: "success",
    //     };
    //   });
    // },
  },
  watch: {
    countDown: function (value) {
      if (value === 0) {
        this.act = true;
        this.token_dialog = false;
      }
    },
  },
};
</script>

<style>
.v-main {
  padding: 0px 0px 0px !important;
}

.container {
  padding: 0px;
}

.banner {
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.607));
}

.regForm {
  background: rgba(0, 0, 0, 0.342);
}

.v-application .error--text {
  color: #f1252575 !important;
}

.base-image-input {
  display: block;
  width: 100%;
  height: 250px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.file-input {
  display: none;
}

.btn-upload {
  top: 150px;
}
</style>
